body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-y: none;
  overflow-y: scroll;
  // cursor:none;
  // cursor:url('./assets/cursor.svg'),auto;
}

.react-pdf__Page__canvas{
  margin: auto;
}
.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations {
  display: none;
}

code {
  font-family: Lato, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h3 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
